import Dataviz from "../../../../content/blog/posts/queue-implementations/src/App.tsx";
import { datasets, sameQueueEnqueueTimes, sameQueueDequeueTimes } from "../../../../content/blog/posts/queue-implementations/src/data-handling.ts";
import { MedianEnqueuePlot } from "../../../../content/blog/posts/queue-implementations/src/MedianEnqueuePlot.tsx";
import { MedianDequeuePlot } from "../../../../content/blog/posts/queue-implementations/src/MedianDequeuePlot.tsx";
import { SameQueueEnqueueTimesPlot } from "../../../../content/blog/posts/queue-implementations/src/SameQueueEnqueueTimesPlot.tsx";
import { SameQueueDequeueTimesPlot } from "../../../../content/blog/posts/queue-implementations/src/SameQueueDequeueTimesPlot.tsx";
import * as React from 'react';
export default {
  Dataviz,
  datasets,
  sameQueueEnqueueTimes,
  sameQueueDequeueTimes,
  MedianEnqueuePlot,
  MedianDequeuePlot,
  SameQueueEnqueueTimesPlot,
  SameQueueDequeueTimesPlot,
  React
};